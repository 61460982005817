/**
 * Tailwinds
 */
@tailwind base;
@tailwind components;
@tailwind utilities;

/**
 * Font Definitions
 */
@font-face {
  font-family: "Lato";
  src: url(./../public/assets/fonts/Lato-Thin.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url(./../public/assets/fonts/Lato-ThinItalic.ttf) format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Lato";
  src: url(./../public/assets/fonts/Lato-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url(./../public/assets/fonts/Lato-Italic.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Lato";
  src: url(./../public/assets/fonts/Lato-Bold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url(./../public/assets/fonts/Lato-BoldItalic.ttf) format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Lato";
  src: url(./../public/assets/fonts/Lato-Black.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url(./../public/assets/fonts/Lato-BlackItalic.ttf) format("truetype");
  font-weight: 900;
  font-style: italic;
}

/* @font-face {
  font-family: "Open Sans";
  src: url(./../public/assets/fonts/OpenSans-VariableFont_wdth,wght.ttf) format("truetype");
  font-style: normal;
  font-weight: 100 900;
}

@font-face {
  font-family: "Open Sans";
  src: url(./../public/assets/fonts/OpenSans-Italic-VariableFont_wdth,wght.ttf)
    format("truetype");
  font-style: italic;
  font-weight: 100 900;
} */

html {
  background: rgb(241, 245, 249);
}

::selection {
  background: #ffea49;
  color: #5a5411;
}
::-moz-selection {
  background: #ffea49;
  color: #5a5411;
}

/**
 * Input Focus Outline Override
 */
*:focus {
  appearance: none;
  outline: none;
  box-shadow: 0 0 0 3px rgba(29, 131, 237, 0.3) !important;
}
